import React from "react"
import {Container} from 'react-bootstrap'
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import PostLink from "../components/post-link"
import AdvertContact from "../components/advert"
import Obfuscate from 'react-obfuscate'

const JobPage = ({
    data: {
      allMarkdownRemark: { edges },
    },
  }) => {

  const Posts = edges
    .filter(edge => (!!edge.node.frontmatter.date)&&(edge.node.frontmatter.type === "job")) // You can filter your posts based on some criteria
    .filter(edge => !edge.node.frontmatter.title.includes("closed"))
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)


  return (
  <>
    <Header />
    <SEO title="Careers Page" />
    <Container className="mt-4">
      <h1>Careers at Opsmorph</h1>
      <p>{"Opsmorph is proud to be a committed Equal Opportunities employer. Opsmorph’s aim is for our workforce to be truly representative of all sections of society and our customers, and for each employee to feel respected and able to operate at their best. We do not discriminate based on age, disability, gender reassignment, marriage and civil partnership, pregnancy and maternity, race (including colour, nationality, and ethnic or national origin), religion or belief, sex and sexual orientation, or any other legally protected characteristics."}</p> 
      <p>{"All open positions at Opsmorph will be advertised on this careers page, from internships to senior consultants. We always welcome hearing from quality candidates. Please direct all enquiries regarding career opportunities to:"}
      <Obfuscate
        email="careers@opsmorph.com"
        className="pl-1"
      />
      </p>
      <div>{Posts}</div>
      <AdvertContact />
    </Container>
    <Footer />
  </>
  )
} 


export default JobPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            type
            level
            salary
            start
            location
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`